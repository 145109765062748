.sidebar {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 60px;
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.1);
  .sidebar-inner {
    width: 100%;
    padding: 15px;
  }
  .menu-item {
    padding: 0px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &.active {
      background-color: #f2eefb;
      .icon {
        svg {
          color: #7f56d9;
        }
      }
      .text {
        span {
          color: #7f56d9;
        }
      }
    }
    a {
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #616161;
    }
    .icon {
      min-width: 32px;
    }
    .text {
      margin: 2px 0;
      span {
        font-size: 14px;
      }
    }
    &.setting {
      margin-top: 100px;
    }
  }
  .sub-menu-item {
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    &.active {
      .icon {
        svg {
          color: #7f56d9;
        }
      }
      .text {
        span {
          color: #7f56d9;
        }
      }
    }
    a {
      padding: 10px 20px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #616161;
    }
    .icon {
      min-width: 32px;
    }
    .text {
      margin: 2px 0;
      span {
        font-size: 14px;
      }
    }
  }
  nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sidebar-bottom-nav {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-top: 50px;
    background-color: #ffffff;
  }
}
