.quotation-create-container {
    padding-bottom: 70px;
    .quotation-icon-group {
        cursor: pointer;

        .quotation-icon {
            color: #4b4b4c;
            font-size: 22px;
            margin-right: 10px;
        }

        .qutation-name {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #4b4b4c;
        }
    }
    .quotation-create-details {
        margin: -20px 20px 20px -20px;

        .quotation-id-box {
            height: 70px;
            width: 400px;
            background-color: #FFFFFF;
            padding: 0px 28px;

            .quotation-id {
                color: #1C1C1C;
                margin: 0;
            }

            .status {
                padding: 4px 8px;
                border-radius: 30px;
                margin-left: 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;

                &.pending {
                    background-color: #FFFBF5;
                    color: #ECB016;
                }
            }
        }

        

    }

    .mark-set-btn {
        background-color: #FFFFFF;
        color: #7F56D9;
        margin-right: 17px;
    }

    .production-price-container {
        background-color: #F9F5FF;
        padding: 25px;

        .title {
            color: #33374D;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .product-name-details {
            margin-bottom: 15px;

            .heading {
                color: #344054;
                opacity: 80%;
                font-size: 13px;
                line-height: 19px;
                font-weight: 400;

            }

            .product-name {
                color: #7F56D9;
            }

            .product-code {
                color: #1C1C1C;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

        }

        .rasi-lab-adress-info {
            .rasi-logo {
                margin-right: 20px;
                max-width: 100%;
                height: auto;
            }

            .text {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #344054;
                opacity: 80%;
                margin-bottom: 4px;
            }

            .lab-name {
                margin-bottom: 6px;
                color: #1C1C1C;
            }

            .address-details {
                color: #1C1C1C;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
            }
        }
    }

    .quotation-details-container {
        background-color: #FFFFFF;

        .quotation-compuny-details {
            padding: 25px;

            .quotation-heading {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #344054;
                opacity: 80%;
                margin-bottom: 20px;
            }

            .buyer-compuny-name {
                .compuny-name-letter {
                    min-width: 80px;
                    height: 80px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #F9F5FF;
                    color: #7F56D9;
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                    margin-right: 20px;
                }

                .compuny-name {
                    color: #1C1C1C;
                }

                .compuny-mailid {
                    color: #1C1C1C;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;

                }

                .compuny-address {
                    color: #1C1C1C;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                }
            }

            .quote-info {
                .heading {
                    color: #344054;
                    opacity: 80%;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                }

                .number {
                    color: #1C1C1C;
                    font-size: 17px;
                    line-height: 24px;
                    font-weight: 600;
                }
            }

        }

        .specification-container {
            padding: 0px 25px 75px 160px;

            .heading-name {
                color: #7F56D9;
                font-weight: 600;
                font-size: 21px;
                line-height: 28px;
                margin: 25px 0px;
            }

            .specification-title {
                color: #344054;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                margin-bottom: 14px;
            }

            .specification-name {
                color: #1C1C1C;
                font-size: 15px;
                line-height: 20px;
                font-weight: 500;
            }

            .term-condition {
                color: #344054;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 40px;
                margin-bottom: 20px;
            }

            .quotation-table {
                padding: 25px 0px 25px 90px;
                display: block;
                width: 100%;

                thead {
                    background-color: #F3EFFC;

                    tr {
                        th {
                            color: #534D59;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 600;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            color: #1B2128;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;

                        }

                        &:hover {
                            background-color: #F9FAFC;
                        }
                    }
                }
            }

            .qutation-condition {
                color: #344054;
                font-size: 14px;
                line-height: 26px;
                font-weight: 300;
                margin-top: 25px;
            }

            .comment-details {
                padding-left: 90px;

                .name {
                    color: #344054;
                    font-size: 16px;
                    line-height: 44px;
                    font-weight: 400;
                }
            }

            .validity-details {
                margin-top: 25px;
                margin-bottom: 18px;

                .validity-title {
                    color: #1C1C1C;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                }

                .validity-info {
                    color: #344054;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }

    }

    .back-btn {
        border-color: #7F56D9;
        border-radius: 5px;
        color: #7F56D9;
        margin-right: 30px;

        &:hover {
            border-color: #7F56D9;

        }
    }
}