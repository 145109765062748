.header {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 60px;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
  .logo {
    display: flex;
    max-width: 100%;
    align-items: flex-start;
  }
  .search-box {
    display: flex;
    width: 100%;
    position: relative;
    input {
      padding: 5px 45px 5px 15px;
      border: none;
      border-radius: 4px;
      background-color: #eef0fa;
    }
    fieldset {
      border: none;
    }
    button {
      position: absolute;
      right: 10px;
      top: 5px;
      padding: 0;
    }
  }
  .header-right-box {
    display: flex;
    width: 100%;
    gap: 15px;
    justify-content: flex-end;
    .header-icons-box {
      display: inline-flex;
      padding-top: 5px;
      gap: 10px;
      .icons-box {
        padding: 0;
        &:hover {
          background-color: transparent;
        }
        .icon {
          padding: 0;
          min-width: 24px;
          &.bell-badge {
            position: relative;
            &::after {
              width: 6px;
              height: 6px;
              position: absolute;
              right: 2px;
              top: -2px;
              content: '';
              border-radius: 50%;
              background-color: #EB0000;
            }
          }
        }
      }
    }
    .header-profile-box {
      display: inline-flex;
      gap: 10px;
      align-items: center;
      .profile-text {
        flex-wrap: wrap;
        width: 60px;
        display: inline-flex;
        align-items: flex-start;
        h6 {
          display: block;
          width: 100%;
          margin: 0;
          font-size: 12px;
          font-weight: 600;
          color: #7f56d9;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          display: flex;
          width: 100%;
          font-size: 11px;
          font-weight: 500;
          color: #858b9a;
        }
      }
    }
  }
}
