.add-new-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
  .heading {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;
    h2 {
      width: 100%;
    }
    span {
      width: 100%;
    }
  }
  .add-new-form-container {
    display: inline-flex;
    flex-wrap: wrap;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    &.add-new-product{
      width: 100%;
    }
    .form-text-field {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      label {
        display: flex;
        width: 100%;
      }
      .text-field {
        display: flex;
        width: 100%;
      }
    }
    .add-new-form-btn {
      margin-top: 30px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 15px;
      }
    }
  }
  .upload-image {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .image {
      display: flex;
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
    }
    .upload-btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      button {
        text-transform: capitalize;
      }
    }
  }
}
