.qutation-form-container {
    .status {
        padding: 4px 8px;
        border-radius: 30px;
        margin-left: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        &.draft {
            background-color: #FFFAEB;
            color: #B54708;
        }
    }

    .sub-title {
        margin-top: 8px;
        color: #667085;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .qutation-form {
        margin-top: 28px;

        .stepper-container {
            padding: 23px 28px;
            background-color: #FFFFFF;
            border: 1px solid #C2C9D1;
            border-radius: 8px;

            .css-14sza3e-MuiStepLabel-root {
                padding: 0px;
                display: flex;
                align-items: flex-start;

                .MuiStepLabel-iconContainer {
                    min-width: 44px !important;
                    height: 44px !important;
                    border: 3px solid #7F56D9;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 0;
                    margin-right: 8px;

                    &.Mui-active {
                        color: #7F56D9;
                    }

                    circle {
                        border: 2px solid #7F56D9;

                    }

                }

                .css-1vyamtt-MuiStepLabel-labelContainer {

                    .css-1hv8oq8-MuiStepLabel-label {
                        color: #A2A8B8;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;

                        &.Mui-active {
                            color: #6C7486;

                        }
                    }

                }
            }


        }

        .details-box {
            padding: 25px;
            background-color: #FFFFFF;
            background-color: #FFFFFF;
            border: 1px solid #C2C9D1;
            border-radius: 8px;

            .title {
                color: #7F56D9;
                margin-bottom: 15px;
                font-weight: 600;
            }

            .box-title {
                color: #344054;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 6px;
            }

            .common-textfield {
                height: 48px;

                .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
                    border-color: #D0D5DD;
                    border-radius: 8px;

                    input {
                        &::placeholder {
                            color: #1C1C1C;
                            opacity: 20%;
                            font-size: 16px;
                            line-height: 21px;
                            font-weight: 400;
                        }
                    }

                    svg {
                        color: #1C1C1C;
                        opacity: 20%;
                    }


                }

                .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                    border-color: #D0D5DD;
                    border-radius: 8px;

                    input {
                        &::placeholder {
                            color: #1C1C1C;
                            opacity: 20%;
                            font-size: 16px;
                            line-height: 21px;
                            font-weight: 400;

                        }
                    }
                }

            }
        }
    }
}